const CategoryWords = {
    General: "",
    Abecedario: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
    Saludos: ["hola", "gracias", "perdon", "como estas", "donde", "buenas noches", "buenas tardes", "buenos dias", "chau", "de nada", "mentira", "mentiroso", "simpatico", "te amo", "te odio", "te quiero", "agresivo"],
    Familia: ["abuela", "abuelo", "bebe", "hijo", "hija", "hijos", "mama", "papa", "prima", "primo", "tia", "tio", "esposo", "esposa", "nieto", "cuñada", "cuñado", "sobrino", "separado", "soltero", "casado", "viudo"],
    Clima: ["frio", "caluroso", "inundacion", "luna", "mar", "niebla", "nublado", "rayo", "rio", "sol", "tornado", "viento"],
    Colores: ["azul", "blanco", "negro", "brillante", "gris", "marron", "naranja", "rojo", "rosa", "verde", "violeta", "celeste", "claro", "oscuro", "amarillo", "transparente", "colores"],
    Números: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "30", "40", "50", "60", "70", "80", "90", "100", "1000", "10000", "1000000"],
    Educación: ["aprender", "nota", "examen", "escribir", "leer", "escuchar", "alumno", "aula", "borrador", "calculadora", "carpeta", "cinta", "clase", "computadora", "cuaderno", "director", "escaner", "filmadora", "fotocopia", "goma de borrar", "impresora", "lapices de colores", "lapiz", "libro", "maestro", "mapa", "papel", "pizarron", "profesor", "proyector", "recreo", "regla", "resaltador", "sacapuntas", "silla", "soga", "tiza"],
    Verbos: ["aceptar", "aprender", "ayudar", "bailar", "caminar", "cocinar", "contar", "dar", "dibujar", "escribir", "leer", "llorar", "mirar", "pintar", "tocar", "decir", "burlar", "armar", "aterrizar", "bañar", "barrer", "capturar", "correr", "desarmar", "encontrar", "lavar", "limpiar", "llamar", "saludar"],
    Salud: ["medico", "sangre", "turno", "hambre", "dolor", "vomito", "mareos", "higado", "analisis", "tomografia", "radiografia", "hueso", "arteria", "pulmon", "garganta", "torax", "grasa", "inflamacion", "operacion", "cirugia", "vacuna", "covid", "orina", "urgente"],
    Meses: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    Almacén: ["agua", "agua saborizada", "almacen", "arroz", "azucar", "cafe", "cebolla", "cerveza", "chicle", "comida", "comprar", "dulce de leche", "efectivo", "fideos", "harina", "huevo", "jamon", "leche", "mate", "mayonesa", "mermelada", "papel higienico", "pan", "papas", "pizza", "queso", "sal", "servilleta", "soda", "tarta", "tomate", "yogur", "aceite", "amargo", "coca cola", "cuanto sale", "detergente", "edulcorante", "esponja", "frio", "hay", "lavandina", "lechuga", "limon", "manteca", "no hay", "precio", "queso untable", "seven up", "tarjeta", "vinagre", "vino", "zanahoria"],
    Ropa: ["sacar", "traer", "remera manga corta", "musculosa", "camisa", "remera manga larga", "pantalon", "bermuda", "pollera", "buzo", "polera", "medias", "ojotas", "sandalias", "zapatillas", "zapatos", "pijama", "camison", "blusa", "pantuflas", "bufanda", "cancan", "traje", "ambo", "perfume", "aros", "desodorante"],
    Legal: ["carnet", "documento", "denuncia", "robo", "seguro", "papeles", "abogado", "años", "carcel", "civil", "condena", "contencioso administrativo", "expediente", "fallo", "fiscal", "interprete", "juez", "laboral", "ladron", "multa", "oficina", "pagar", "penal", "policia", "resolucion", "sentencia", "tribunal"],
    Países: ["argentina", "brasil", "canada", "chile", "colombia", "costa rica", "cuba", "ecuador", "mexico", "panama", "paraguay", "peru", "venezuela", "estados unidos", "uruguay"],
    Provincias: ["buenos aires", "entre rios", "formosa", "la pampa", "la rioja", "neuquen", "rio negro", "san luis", "santa cruz", "catamarca", "santa fe", "chaco", "chubut", "cordoba", "santiago del estero", "tierra del fuego", "tucuman", "islas malvinas", "mendoza", "salta", "corrientes", "jujuy", "misiones", "san juan"],
    Juegos: ["ajedrez", "domino", "cartas", "escondidas", "pool", "truco", "chancho", "chinchon", "dados", "damas", "escoba de 15", "uno", "rompecabezas"],
    Deportes:  ["atletismo", "automovilismo", "basquet", "ciclismo", "futbol", "golf", "handball", "hockey", "natacion", "padel", "patin", "rugby", "surf", "tenis", "voley"],
};

export default CategoryWords;