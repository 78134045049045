import React, { useRef, useState, useMemo, useEffect } from 'react';
// @ts-ignore
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { useAnimations } from '@react-three/drei';
import * as THREE from 'three';
import animationsMap from "./AnimationsMap.tsx";
import Hoodie from "./Hoodie";
import Sweater from "./Sweater";
import Shirt from './Shirt';
import Tee from './Tee';
import { processString } from "../utils/processString";
import { fetchAndDecompressFBX } from "../api/api.ts";
import { AnimationClip } from "three";
import { useFrame } from "@react-three/fiber";
import { debounce } from 'lodash'; // Import lodash's debounce utility

function LoaderMesh() {
    const meshRef = useRef();

    useFrame(({ clock }) => {
        if (meshRef.current) {
            // @ts-ignore
            meshRef.current.rotation.z += 0.03;
            const elapsedTime = clock.getElapsedTime();
            // @ts-ignore
            meshRef.current.material.opacity = 0.7 + 0.3 * Math.sin(elapsedTime * 3);
        }
    });

    return (
        <mesh ref={meshRef} position={[0, 0, 0]}>
            <torusBufferGeometry args={[0.5, 0.05, 16, 100, Math.PI * 1.75]} />
            <meshPhysicalMaterial
                color="#7fcff5"
                emissive="#7fcff5"
                roughness={0.1}
                metalness={0.8}
                clearcoat={0.4}
                clearcoatRoughness={0.1}
                opacity={1}
            />
        </mesh>
    );
}

function AnimatedModel({ clothing, word, speed }) {
    const [loadedAnimations, setLoadedAnimations] = useState<Record<string, AnimationClip>>({});
    const [loading, setLoading] = useState(true);
    const group = useRef<THREE.Group>(null);

    useEffect(() => {
        // Define the function outside to avoid re-creating it on every render
        const loadFBXAnimations = debounce(async () => {
            setLoading(true);
            const loader = new FBXLoader();
            const animationsMap = {};

            const loadAnimation = async (key, fbxUrl) => {
                return new Promise((resolve) => {
                    loader.load(
                        fbxUrl,
                        (object) => {
                            const animations = object.animations || [];
                            const animation = animations.length > 0 ? animations[0] : null;
                            resolve({ key, animation });
                        },
                        undefined,
                        (error) => {
                            console.error(`Error loading animation for ${key}:`, error);
                            resolve({ key, animation: null });
                        }
                    );
                });
            };

            try {
                let words = processString(word);
                words.push('idle');

                const results = await Promise.all(
                    Object.entries(words).map(async (key) => {
                        try {
                            const fbxUrl = await fetchAndDecompressFBX(key[1]);
                            return await loadAnimation(key[1], fbxUrl);
                        } catch (error) {
                            console.warn(`Error fetching or decompressing animation for ${key[1]}:`, error);

                            // Fallback to loading individual letter animations
                            const letters = key[1].split('');
                            const letterResults = await Promise.all(
                                letters.map(async (letter) => {
                                    try {
                                        const letterUrl = await fetchAndDecompressFBX(letter);
                                        return await loadAnimation(letter, letterUrl);
                                    } catch (letterError) {
                                        console.error(`Error loading animation for letter ${letter}:`, letterError);
                                        return { key: letter, animation: null };
                                    }
                                })
                            );

                            // Filter out null animations and add them to animationsMap
                            letterResults.forEach(({ key: letterKey, animation }) => {
                                if (animation) {
                                    animation.name = letterKey;
                                    animationsMap[letterKey] = animation;
                                }
                            });

                            // Return null for the whole word if the fallback to letters was used
                            return { key: key[1], animation: null };
                        }
                    })
                );

                results.forEach(({ key, animation }) => {
                    if (animation) {
                        animation.name = key;
                        animationsMap[key] = animation;
                    }
                });

                setLoadedAnimations(animationsMap);
            } catch (globalError) {
                console.error('Error loading animations:', globalError);
            } finally {
                setLoading(false);
            }
        }, 300); // 300ms debounce interval to reduce frequent calls

        loadFBXAnimations();

        // Clean up function for debounce
        return () => {
            loadFBXAnimations.cancel();
        };
    }, []);

    const { actions, mixer } = useAnimations(Object.values(loadedAnimations) as AnimationClip[], group);

    useEffect(() => {
        if (!actions) return;

        const playIdleAnimation = () => {
            if (actions.idle) {
                // @ts-ignore
                actions.idle.reset().setLoop(THREE.LoopRepeat).play();
            }
        };

        const playPhraseAnimations = async () => {
            let lastAction = null;
            const words = processString(word);

            for (const part of words) {
                if (actions[part]) {
                    const action = actions[part];
                    action.clampWhenFinished = true;
                    action.timeScale = 1.8 * speed;
                    if (lastAction && lastAction !== action) {
                        action.reset().setLoop(THREE.LoopOnce, 1).crossFadeFrom(lastAction, 0.5, false).play();
                    } else {
                        action.reset().setLoop(THREE.LoopOnce, 1).play();
                    }
                    await new Promise(resolve => setTimeout(resolve, 1800 / speed));
                    action.fadeOut(0.5);
                    lastAction = action;
                } else {
                    lastAction = await playWordSpellingAnimations(part);
                }
            }
            return lastAction;
        };

        const playWordSpellingAnimations = async (spelling) => {
            let lastAction = null;
            for (const char of spelling) {
                const action = actions[char];
                if (action) {
                    action.clampWhenFinished = true;
                    action.timeScale = 1.5 * speed;
                    if (lastAction && lastAction !== action) {
                        action.reset().setLoop(THREE.LoopOnce, 1).crossFadeFrom(lastAction, 0.5, false).play();
                    } else {
                        action.reset().setLoop(THREE.LoopOnce, 1).play();
                    }
                    await new Promise(resolve => setTimeout(resolve, 1500 / speed));
                    action.fadeOut(0.5);
                    lastAction = action;
                }
            }
            return lastAction;
        };

        const playAnimation = async () => {

            const lastPlayedAction = await playPhraseAnimations();
            if (lastPlayedAction && actions.idle) {
                // @ts-ignore
                actions.idle.reset().setLoop(THREE.LoopRepeat).crossFadeFrom(lastPlayedAction, 0.5, false).play();
            } else {
                playIdleAnimation();
            }
        };

        playAnimation();
    }, [actions, mixer, word, speed]);

    const getModelComponent = () => {
        switch (clothing) {
            case 'hoodie':
                return <Hoodie />;
            case 'sweater':
                return <Sweater />;
            case 'shirt':
                return <Shirt />;
            case 'tee':
                return <Tee />;
            default:
                return null;
        }
    };

    return (
        <>
            {loading ? (
                <LoaderMesh />
            ) : (
                <group ref={group} position={[0, -2.8, 3]} scale={[2, 2, 2]}>
                    {getModelComponent()}
                </group>
            )}
        </>
    );
}

export default AnimatedModel;
