import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {Box}from "@mui/material";
import './styles.css';

const clothingOptions = {
    hoodie: {
        label: "Hoodie",
        image: "/hoodie.png"
    },
    sweater: {
        label: "Sweater",
        image: "/sweater.png"
    },
    shirt: {
        label: "Camisa",
        image: "/shirt.png"
    },
    tee: {
        label: "Remera",
        image: "/tee.png"
    }
};

export default function BasicSelect({ onSelectionChange }) {
    const [clothing, setClothing] = useState('');

    const handleChange = (event) => {
        const selectedClothing = event.target.value;
        setClothing(selectedClothing);
        onSelectionChange(selectedClothing);
    };

    return (
        <Box className='select-clothes-container' sx={{ minWidth: 150 }}>
            <FormControl className="custom-text-field" fullWidth>
                <InputLabel id="demo-simple-select-label">Apariencia</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clothing}
                    label="Apariencia"
                    onChange={handleChange}
                    renderValue={(value) => (
                        <div className="selected-item">
                            <img src={clothingOptions[value].image} alt={clothingOptions[value].label} className="item-image" />
                            {clothingOptions[value].label}
                        </div>
                    )}
                >
                    {Object.keys(clothingOptions).map((key) => (
                        <MenuItem key={key} value={key}>
                            <img src={clothingOptions[key].image} alt={clothingOptions[key].label} className="item-image" />
                            {clothingOptions[key].label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}