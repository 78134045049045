import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import SignIn from './views/SignIn';
import Login from './views/Login';
import { validateToken } from './api/api';
import PrivateRoute from './components/PrivateRoute';
import AvatarView from "./views/AvatarView";
import Profile from "./views/Profile";
import Dictionary from "./views/Dictionary";

function App() {
    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        async function refreshToken() {
            const auth = localStorage.getItem('pocketbase_auth');
            if (!auth) {
                setIsAuth(false);
                return;
            }

            const authObject = JSON.parse(auth);
            try {
                const res = await validateToken(authObject.token);
                if (res.token) {
                    setIsAuth(true);
                    localStorage.setItem('pocketbase_auth', JSON.stringify(res));
                } else {
                    setIsAuth(false);
                }
            } catch (error) {
                console.log(error);
                setIsAuth(false);
            }
        }
        refreshToken();
    }, []);

    return (
        <Router>
            <div className="full-size">
                <Routes>
                    <Route element={<PrivateRoute/>}>
                        <Route
                            path="/"
                            element={<AvatarView/>}/>
                        <Route
                            path="/diccionario"
                            element={<Dictionary/>}/>
                        <Route
                            path="/perfil"
                            element={
                                <Profile/>
                            }
                        />
                    </Route>

                    <Route
                        path="/ingresar"
                        element={<Login setIsAuth={setIsAuth}/>}/>
                    <Route
                        path="/registrar"
                        element={<SignIn/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;