import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import animationsMap from "../AnimationsMap.tsx";
import './styles.css';
import categoryWords from "../CategoryWords.tsx";
import {processString} from "../../utils/processString.ts";
import useDeviceType from "../../utils/useDeviceType.ts";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

export default function DictionarySelect({onWordSelect, selectedCategory}) {
    const deviceType = useDeviceType();

    let options;
    if (selectedCategory == null || selectedCategory === 'General') {
        options = Object.keys(animationsMap);
    }else{
        console.log(selectedCategory);
        options = Object.values(categoryWords[selectedCategory]);
    }
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        if (selectedValue) {
            console.log(processString(selectedValue)[0]);
            onWordSelect(processString(selectedValue)[0]);
        }
    };

    const handleSelectionChange = (event: React.SyntheticEvent, value: string | null) => {
        if (value) {
            console.log(processString(value)[0]);
            onWordSelect(processString(value)[0]);
        }
    };

    return (
        <div className='dictionary-select-container'>
            {deviceType === 'mobile' ? (
                <FormControl sx={{ minWidth: 250 }}>
                    <InputLabel id="word-select-label">Busca una palabra</InputLabel>
                    <Select
                        labelId="word-select-label"
                        id="word-select-demo"
                        onChange={handleSelectChange}
                        displayEmpty
                        label="Busca una palabra"

                    >
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <Autocomplete
                    id="word-select-demo"
                    sx={{ minWidth: 250 }}
                    options={options}
                    autoHighlight
                    onChange={handleSelectionChange}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {option}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Busca una palabra"
                            // @ts-ignore
                            slotProps={{
                                htmlInput: {
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                },
                            }}
                        />
                    )}
                />
            )}
        </div>
    );
}