import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import SportsTennisOutlinedIcon from '@mui/icons-material/SportsTennisOutlined';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import useDeviceType from '../../utils/useDeviceType';

import './styles.css';
import CategoryWords from "../CategoryWords.tsx";
import {MenuItem} from "@mui/material";

const iconMap: Record<string, JSX.Element> = {
    General: <AppsIcon  />,
    Abecedario: <AbcOutlinedIcon />,
    Saludos: <EmojiPeopleOutlinedIcon />,
    Familia: <FamilyRestroomOutlinedIcon />,
    Clima: <CloudOutlinedIcon />,
    Colores: <FormatColorFillOutlinedIcon />,
    Números: <Looks4OutlinedIcon />,
    Educación: <AutoStoriesOutlinedIcon />,
    Verbos: <DirectionsWalkOutlinedIcon />,
    Salud: <HealthAndSafetyOutlinedIcon />,
    Meses: <CalendarMonthOutlinedIcon />,
    Almacén: <LocalGroceryStoreOutlinedIcon />,
    Ropa: <CheckroomOutlinedIcon />,
    Legal: <GavelOutlinedIcon />,
    Países: <PublicOutlinedIcon />,
    Provincias: <MapOutlinedIcon />,
    Juegos: <ExtensionOutlinedIcon />,
    Deportes: <SportsTennisOutlinedIcon />
};

export default function DictionaryCategory({ onWordSelect }) {
    const options = Object.keys(CategoryWords);
    const deviceType = useDeviceType();

    const handleSelectionChange = (event: React.SyntheticEvent, value: string | null) => {
        if (value) {
            console.log(value);
            onWordSelect(value);
        }
    };

    return (
        <div className='dictionary-category-container'>
            {deviceType === 'mobile' ? (
                <TextField
                    select
                    label="Selecciona una categoría"
                    onChange={(e) => handleSelectionChange(e, e.target.value)}
                    sx={{ minWidth: 250 }}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ color: 'rgb(46,173,234)' }}>
                                    {iconMap[option]}
                                </Box>
                                <span style={{ marginLeft: 8 }}>{option}</span>
                            </Box>
                        </MenuItem>
                    ))}
                </TextField>
            ) : (
                <Autocomplete
                    id="word-select-demo"
                    sx={{ minWidth: 250 }}
                    options={options}
                    autoHighlight
                    onChange={handleSelectionChange}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Box sx={{ color: 'rgb(46,173,234)' }}>
                                {iconMap[option]}
                            </Box>
                            <span style={{ marginLeft: 8 }}>{option}</span>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Selecciona una categoría"
                            // @ts-ignore
                            slotProps={{
                                htmlInput: {
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                },
                            }}
                        />
                    )}
                />
            )}
        </div>
    );
}
