import React, { useState} from 'react';
import 'regenerator-runtime/runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MenuModal from '../../components/MenuModal';
import './styles.css';
import { Canvas } from "@react-three/fiber";
import BasicSelect from "../../components/SelectClothes";
import { Experience } from "../../components/Experience.tsx";
import Chat from '../../components/Chat';
import BottomNav from '../../components/BottomNav';
import useDeviceType from '../../utils/useDeviceType';
import ReplayButton from "../../components/ReplayButton";
import SpeedButton from "../../components/SpeedButton";

const AvatarView = () => {
    const [clothing, setClothing] = useState('hoodie');
    const [updatedText, setUpdatedText] = useState({ text: 'hola', timestamp: 0 });
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [speed, setSpeed] = useState(1);
    const deviceType = useDeviceType();
    const logoSize = deviceType === 'mobile' ? '140px' : '192px';

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const handleSelectionChange = (selectedClothing) => {
        setClothing(selectedClothing);
    };

    const handleTextUpdate = ({ text, timestamp }) => {
        setUpdatedText({ text, timestamp });
    };

    const handleReplayClick = () => {
        if (updatedText.text) {
            const timestamp = new Date().getTime();
            handleTextUpdate({ text: updatedText.text, timestamp });
        }
    };

    const toggleChat = () => {
        setIsChatOpen((prev) => !prev);
        if (isSelectOpen) setIsSelectOpen(false);
    };

    const closeChat = () => {
        setIsChatOpen(false);
    };

    const toggleSelect = () => {
        setIsSelectOpen((prev) => !prev);
        if (isChatOpen) setIsChatOpen(false);
    };

    const startListening = () => {
        console.log('Start listening');
        SpeechRecognition.startListening({
            continuous: false,
            language: 'es-AR',
            interimResults: true
        });
    };

    const stopListening = () => {
        console.log('Stop listening');
        SpeechRecognition.stopListening();
        console.log('Transcript:', transcript);

        if (transcript.trim()) {
            handleTextUpdate({ text: transcript, timestamp: Date.now() });
        }

        resetTranscript();
    };

    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        handleReplayClick();
    };

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div className='avatar-container'>
            <MenuModal />
            <div>
                <div className='avatar-logo'>
                    <a href="/">
                        <img
                            src="/dillo-brand.png"
                            style={{ width: logoSize }}
                            alt="Dillo Brand"
                        />
                    </a>
                </div>
                {deviceType === 'desktop' && (
                    <>
                        <Chat onTextUpdate={handleTextUpdate} onClose={undefined} onMouseDown={startListening} onMouseUp={stopListening} />
                        <ReplayButton onClick={handleReplayClick} />
                        <SpeedButton onSpeedChange={handleSpeedChange} />
                        <BasicSelect onSelectionChange={handleSelectionChange} />
                    </>
                )}
            </div>
            <div className={`canvas-container ${deviceType === 'mobile' ? 'mobile' : ''}`}>
                <Canvas shadows camera={{ position: [0, 0, 8], fov: 42 }}>
                    <Experience clothing={clothing} text={updatedText.text} key={`${clothing}-${updatedText.timestamp}`}  speed={speed || 0.5}  />
                </Canvas>
                <div
                    className="text-overlay"
                    style={{ fontSize: deviceType === 'mobile' ? '1em' : '1.5em',
                        top: deviceType === 'mobile' ? '18%' : '10%',
                    }}
                >
                    {updatedText.text}
                </div>
            </div>
            {deviceType === 'mobile' && (
                <>
                    <ReplayButton onClick={handleReplayClick} />
                    <SpeedButton onSpeedChange={handleSpeedChange} />
                    <BottomNav isDictionaryOpen={false}  onChatClick={toggleChat} onClothingClick={toggleSelect} />
                    {isChatOpen && <Chat onTextUpdate={handleTextUpdate} onClose={closeChat} onMouseDown={startListening} onMouseUp={stopListening}/>}
                    {isSelectOpen && <BasicSelect onSelectionChange={handleSelectionChange} />}
                </>
            )}
        </div>
    );
};

export default AvatarView;
