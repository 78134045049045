// Define the list of connectors
const conectores = [
    "los", "de", "el", "la", "del", "lo", "en", "con", "por", "sin", "son",
    // Adición
    "y", "además", "también", "igualmente", "asimismo", "incluso", "por añadidura", "de igual forma", "de la misma manera",
    "más aún", "a continuación", "ni", "por otra parte", "por otro lado", "de hecho", "no solo", "sino también",

    // Causa
    "porque", "debido a", "ya que", "puesto que", "en vista de que", "dado que", "gracias a", "a causa de",
    "por motivo de", "por razón de", "como resultado de", "en virtud de", "por culpa de", "a raíz de", "en razón de",

    // Consecuencia
    "por lo tanto", "por consiguiente", "así que", "de modo que", "en consecuencia", "por esta razón", "por eso",
    "entonces", "de ahí que", "de tal manera que", "por lo cual", "resulta que", "por este motivo", "de esta forma",

    // Oposición
    "pero", "sin embargo", "no obstante", "a pesar de", "aunque", "por el contrario", "al contrario", "en cambio",
    "con todo", "aún así", "de todas formas", "de todas maneras", "a pesar de todo", "en cualquier caso",
    "aun así", "con todo y eso", "por otro lado", "sin embargo", "no obstante", "al margen de",

    // Comparación
    "como", "igual que", "tal como", "de igual modo", "de la misma forma", "así como", "tal cual", "similar a",
    "igual a", "análogo a", "de manera similar", "lo mismo que", "de igual manera", "de forma parecida",

    // Condición
    "si", "siempre que", "a menos que", "con tal de que", "en caso de que", "suponiendo que", "a condición de que",
    "a no ser que", "si acaso", "excepto si", "con la condición de que", "mientras que", "solo si", "salvo que",
    "dado el caso que", "de ocurrir que", "de suceder que",

    // Concesión
    "aunque", "a pesar de que", "por más que", "si bien", "aun cuando", "aun si", "pese a que", "incluso si",
    "por mucho que", "aunque parezca", "por más que", "aunque sea", "a pesar de", "sin importar que",

    // Ejemplificación
    "por ejemplo", "en particular", "tal como", "especialmente", "como muestra", "concretamente", "pongamos por caso",
    "en especial", "para ilustrar", "en otras palabras", "por citar", "por caso", "a modo de ejemplo",

    // Explicación
    "es decir", "o sea", "esto es", "en otras palabras", "mejor dicho", "a saber", "en definitiva", "en resumen",
    "dicho de otro modo", "en pocas palabras", "específicamente", "vale decir", "o mejor dicho",

    // Temporalidad
    "mientras", "al mismo tiempo", "después", "antes", "luego", "mientras tanto", "a continuación",
    "más tarde", "seguidamente", "una vez que", "desde entonces", "de repente", "al principio", "en ese momento",
    "al cabo de", "al final", "a partir de", "antes de", "tras", "en cuanto", "a partir de ahora",

    // Finalidad
    "para", "a fin de", "con el propósito de", "con el objetivo de", "a efectos de", "con la intención de",
    "para que", "a fin de que", "con vistas a", "en aras de", "en beneficio de", "con miras a", "para que",
    "con el fin de",

    // Secuencia
    "primero", "segundo", "a continuación", "luego", "después", "finalmente", "para empezar", "en primer lugar",
    "en segundo lugar", "por último", "acto seguido", "posteriormente", "de inmediato", "a renglón seguido",
    "antes de nada", "a continuación", "a posteriori", "seguidamente",

    // Resumen
    "en resumen", "en conclusión", "para resumir", "en pocas palabras", "en definitiva", "en breve", "sintetizando",
    "en suma", "para finalizar", "como conclusión", "para concluir", "resumiendo", "en síntesis", "en resumen",
    "en resumidas cuentas", "de manera resumida", "en pocas palabras", "dicho brevemente"
];

// Define pairs to combine with an underscore
const pairsToCombine = ["como estas", "agua saborizada", "buenos aires", "dulce de leche", "coca cola", "cuanto sale", "no hay",
    "papel higienico", "queso untable", "seven up","costa rica", 'semana que viene', 'todos los dias', 'todos los meses', 'seña personal',
    'estados unidos','buenas noches', 'buenas tardes', 'buenos dias', 'de nada', 'te amo', 'te odio', 'te quiero', 'goma de borrar',
    'lapices de colores', 'remera manga corta', 'remera manga larga', 'contencioso administrativo', 'entre rios', 'la pampa', 'la rioja',
    'rio negro', 'san luis', 'santa cruz', 'santa fe', 'santiago del estero', 'tierra del fuego', 'islas malvinas', 'san juan',
    'escoba de 15', 'un millon', 'reproductor de musica' ]

export function processString(input: string): string[] {
    // Define a mapping of accented characters to their unaccented equivalents
    const accentsMap: { [key: string]: string } = {
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ñ': 'n',
        'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ñ': 'N'
    };

    // Convert the input to lowercase, remove special characters, and replace accented characters
    input = input
        .toLowerCase()
        .replace(/[áéíóúñ]/g, match => accentsMap[match]) // Replace accented characters
        .replace(/[^\w\s]/gi, ''); // Remove special characters

    // Split the input string into words
    const words = input.split(/\s+/);

    // Create a Set for faster lookup of pairs to combine
    const pairsSet = new Set(pairsToCombine);

    // Create a Set for faster lookup of connectors
    const connectorsSet = new Set(conectores);

    // Result array to store processed words
    const result: string[] = [];

    // Iterate over the words array
    for (let i = 0; i < words.length; i++) {
        // Attempt to get a 3-word pair
        let combined = false;

        const getWords = (n) => words.slice(i, i + n).join(' ');

        for (let n = 3; n >= 1; n--) {
            if (i + n <= words.length) {
                const phrase = getWords(n);
                if (pairsSet.has(phrase)) {
                    result.push(phrase.replace(/ /g, '_'));
                    i += n - 1; // Skip the next n-1 words as they are already combined
                    combined = true;
                    break;
                }
            }
        }

        if (!combined) {
            if (!connectorsSet.has(words[i])) {
                result.push(words[i]);
            }
            // else, it's a connector not part of a pair, skip it
        }
    }
    return result;
}
